import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateRangeSelector,
  ExternalSourceSelector,
  SearchValueField,
  SystemTypeSelector,
  useFilterContext,
} from '@volvo/vce-package-site-uikit';
import { Button, ButtonVariant } from '@volvo/vce-uikit';
import { Container } from './styles';

export const Filter: FC = () => {
  const { t } = useTranslation();
  const { handleResetFilter } = useFilterContext();

  const onClearAllClick = () => {
    handleResetFilter();
  };

  return (
    <Container>
      <div>
        <SearchValueField
          label={t('load-tickets.search-box-placeholder')}
          debounceTime={500}
          hasApplyOnChange={true}
        />
      </div>
      <ExternalSourceSelector hasFloatingLabel={true} hasApplyOnChange={true} />
      <SystemTypeSelector hasFloatingLabel={true} hasApplyOnChange={true} />
      <DateRangeSelector maxRange={90} hasApplyOnChange={true} />
      <Button
        variant={ButtonVariant.Secondary}
        iconStart="close"
        onClick={onClearAllClick}
        size="medium"
      >
        {t('load-tickets.clear-buttton-text')}
      </Button>
    </Container>
  );
};
